import * as React from "react"
import { Header, PricingPlan, Footer, BottomNavbar } from "../components/index"
import { Helmet } from "react-helmet"

import SubTitle from "../components/Common/SubTitle/SubTitle"
import Container from "../components/Common/Container/Container"

const IndexPage = () => {
  return (
    <div>
      <Helmet>
        <title>Esteekey - Pricing Plan</title>
        <meta name="description" content="Esteekey - #1 Fitness app for 2022" />
        <meta name="theme-color" content="#ffffff" data-react-helmet="true" />
      </Helmet>
      <Header />
      <div className="min-h-[calc(100vh-158px)] pt-[69px] xl:pt-0">
        <div className="pt-6 xl:pt-16 pb-6  px-4 sm:px-0  bg-gray-lightest ">
          <Container>
            <SubTitle title1="Pricing" title2="Plans" text="Pick your path" />
          </Container>
        </div>
        <PricingPlan />
      </div>
      <BottomNavbar />
      <Footer />
    </div>
  )
}

export default IndexPage
